<template>
  <CRSelect
    :id="`${id}-dot-status-type-filter-select-dot-status-type`"
    v-model="dotAuthorityTypeSelections"
    :items="dotAuthorityTypes"
    chips
    flat
    hide-details
    item-text="label"
    item-value="id"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dotAuthorityTypes: [
        {
          id: 1,
          label: 'Yes',
        },
        {
          id: 0,
          label: 'No',
        },
      ],
      dotAuthorityTypeSelections: [],
      filter: {
        column: {
          _t_id: 'f95ef17e-3011-11ef-9454-0242ac120002',
          prop: 'dotAuthorizedForPassenger',
          filterType: 'eq',
        },
        activeFilter: undefined,
        hideOnFilterBar: true,
      },
    }
  },
  async mounted() {
    const activeFilter = this.findFilter(this.filter)
    if (activeFilter) {
      this.activeFilter = activeFilter
      this.$nextTick(() => {
        const rebuiltSelections = activeFilter.value
        if (!!rebuiltSelections) {
          this.dotAuthorityTypeSelections = rebuiltSelections
            .split(' ')
            .reduce((acc, selection) => {
              const num = parseInt(selection)
              if (!isNaN(num) && num >= 0) {
                acc.push(num)
              }
              return acc
            })
          this.addFilter()
        }
      })
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.filters.remove(this.filter)

      if (this.dotAuthorityTypeSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addDOTAuthorityTypeFilter()
      }

      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addDOTAuthorityTypeFilter() {
      const newFilter = deepClone(this.filter)
      newFilter.value = this.dotAuthorityTypeSelections.join(' ')
      newFilter.stepParent = this.column?._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>
