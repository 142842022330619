<template>
  <CRSelect
    :id="`${id}-operating-status-type-filter-select-operating-status-type`"
    v-model="tierTypeSelection"
    :items="tierTypes"
    flat
    hide-details
    item-text="name"
    item-value="value"
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { filter } from '@/utils/filter'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tierTypes: [
        {
          value: "tier_1",
          name: 'Tier 1',
        },
        {
          value: "tier_2",
          name: 'Tier 2',
        },
        {
          value: "tier_3",
          name: 'Tier 3',
        },
        {
          value: "no_tier",
          name: 'No tier'
        }
      ],
      tierTypeSelection: null,
      gteFilter: {
        column: {
          _t_id: '543f282e-8806-11ef-b864-0242ac120002',
          prop: 'quality',
          filterType: 'gte'
        }
      },
      ltFilter: {
        column: {
           _t_id: 'c0582a18-8808-11ef-b864-0242ac120002',
          prop: 'quality',
          filterType: 'lt'
        }
      },
      nullFilter: {
        column: {
           _t_id: '9a27f1ce-8809-11ef-b864-0242ac120002',
          prop: 'quality',
          filterType: 'isnull'
        },
        value: 1
      },
    }
  },
  methods: {
    setFilter() {
      this.filters.remove(this.gteFilter)
      this.filters.remove(this.ltFilter)
      this.filters.remove(this.nullFilter)

      if (this.tierTypeSelection) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addTierTypeFilter()
      }
      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addTierTypeFilter() {
      switch (this.tierTypeSelection) {
        case "tier_1":
          const tier1Filter = deepClone(this.gteFilter)
          tier1Filter.value = 0.8
          tier1Filter.stepParent = this.column?._t_id
          this.filters.add(this.grandParentFilter, tier1Filter)

          break
        case "tier_2":
          const tier2GteFilter = deepClone(this.gteFilter)
          tier2GteFilter.value = 0.6
          tier2GteFilter.stepParent = this.column?._t_id
          const tier2LtFilter = deepClone(this.ltFilter)
          tier2LtFilter.value = 0.8
          tier2LtFilter.stepParent = this.column?._t_id
          this.filters.add(this.grandParentFilter, tier2GteFilter)
          this.filters.add(this.grandParentFilter, tier2LtFilter)
          break
        case "tier_3":
          const tier3Filter = deepClone(this.ltFilter)
          tier3Filter.value = 0.6
          tier3Filter.stepParent = this.column?._t_id
          this.filters.add(this.grandParentFilter, tier3Filter)
          break
        case "no_tier":
          const noTierFilter = deepClone(this.nullFilter)
          noTierFilter.stepParent = this.column?._t_id
          this.filters.add(this.grandParentFilter, this.nullFilter)
          break
        default:
          break
      }
    },
  },
}
</script>
