import Vue from 'vue'
import router from '@/router/index'
import { store } from '@/state/store'

export default async function (component, user) {
  const MenuDataMapper = function (row, rowProps, handleAction, user) {
    return [
      {
        icon: () => 'remove_red_eye',
        viewBox: '0 0 24 24',
        title: () => 'View',
        click: () => handleAction('VIEW', rowProps),
        quickAccess: true,
      },
      {
        icon: () => 'edit',
        viewBox: '0 0 24 24',
        title: () => 'Edit',
        click: (() => async () => {
          const component = () => import('./EditCompanySidebar.vue')
          store.dispatch('app/openSidebarDialog', {
            data: {
              companyId: row.companyId,
              title: 'Edit Operator',
            },
            component,
          })
        })(),
      },
      //   {
      //     icon: () => '',
      //     title: () => 'Availability',
      //     click: () => handleAction('ADD_CHARGE', rowProps),
      //   },
      {
        icon: () => 'rates',
        viewBox: '0 0 19 21',
        title: () => 'Rates',
        color: () => 'primary',
        click: () => handleAction('RATES', rowProps),
      },
      {
        icon: () => 'remove_red_eye',
        viewBox: '0 0 24 24',
        title: () => 'View Details',
        click: () => {
          const route = router.resolve({
            name: 'affiliates-detail',
            params: {
              id: row.companyId,
            },
          })
          window.open(route.href, '_blank')
        },
      },
      {
        icon: () => 'calendar',
        viewBox: '0 0 32 32',
        title: () => 'Availability',
        click: () => {
          router.push({
            name: 'affiliates-availability',
            params: { company: row },
          })
        },
      },
    ]
  }

  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(
            this.row,
            this.rowProps,
            this.handleAction,
            user
          ),
        },
      })
    },
  })
}
