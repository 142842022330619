<template>
  <v-container style="padding: 0px; margin: 0">
    <template v-if="row.action === 'VIEW'">
      <v-container>
        <AffiliatesViewDetail :row="row" @row-collapse="collapseRow" />
      </v-container>
    </template>
    <template v-if="row.action === 'RATES'">
      <v-container>
        <AffiliatesRateDetail :row="row" @row-collapse="collapseRow" />
      </v-container>
    </template>
  </v-container>
</template>

<script>
import AffiliatesViewDetail from '@/components/affiliatesViewDetail.vue'
import AffiliatesRateDetail from '@/components/AffiliatesRatesDetail.vue'

export default {
  components: {
    AffiliatesViewDetail,
    AffiliatesRateDetail,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  async mounted() {},
  methods: {
    refreshQuery() {
      this.$emit('refresh-query-request')
    },
    collapseRow() {
      this.refreshQuery()
      this.props.expanded = false
    },
  },
}
</script>

<style lang="scss">
.v-btn.remove-right-margin {
  margin-right: 0 !important;
}

.detail-container {
  padding: 4px;
  background-color: $blue-pale;
}

.detail {
  padding: 10px;
}

.half-width {
  width: 50%;
}

.center-contents {
  text-align: center;
}

.contact-detail {
  margin-top: 0;
}

.padded {
  padding: 20px;
}

.float-right {
  float: right;
}

.has-border {
  border-top: 1px solid gray;
  border-left: 1px solid gray;

  &.top-right {
    border-right: 1px solid gray;
    border-top-right-radius: 4px;
  }

  &.top-left {
    border-top-left-radius: 4px;
  }

  &.bottom-left {
    border-bottom: 1px solid gray;
    border-bottom-left-radius: 4px;
  }

  &.bottom-right {
    border-right: 1px solid gray;
    border-bottom: 1px solid gray;
    border-bottom-right-radius: 4px;
  }
}
</style>
