import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Market, MarketRate } from '@/models/dto/Market'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<MarketRate>>> {
    const {
      pageSize = 50,
      page = 1,
      sorts = null,
      filters = null,
      showAllRates = false,
      additionalQueries = null,
    } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (showAllRates) {
      query += `&showAllRates=true`
    }
    if (additionalQueries) {
      query += `&${additionalQueries}`
    }
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    const url = `https://${baseUrl()}/tables/market-rates?${query}`

    return httpService.get(url)
  },
  create(payload: MarketRate) {
    const url = `https://${baseUrl()}/marketRates`
    return httpService.post(url, payload)
  },
  delete(marketRateId: number) {
    const url = `https://${baseUrl()}/marketRates/${marketRateId}`
    return httpService.delete(url)
  },
  update(payload: MarketRate) {
    const url = `https://${baseUrl()}/marketRates/${payload.marketRateId}`
    return httpService.patch(url, payload)
  },
  auditTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<any>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(
      `https://${baseUrl()}/tables/market-rate-audits?${query}`
    )
  },
  auditTableExport(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<any>>> {
    const { sorts, filters } = params
    let query = ''
    if (sorts) {
      query += query.length ? `&${sorts}` : `${sorts}`
    }
    if (filters) {
      query += query.length ? `&${filters}` : `${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/market-rate-audits/export?${query}`
    return httpService.get(url, { responseType: 'blob' })
  },
  getSelectedOperatorRatesDetail(params) {
    const { customerAccountId, companyId } = params
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${customerAccountId}/editSelectedOperatorRates/${companyId}`
    return httpService.get(url)
  },
  updateSelectedOperatorRates(params) {
    const { customerAccountId, companyId, body } = params
    const host = baseUrl()
    const url = `https://${host}/coachrail/v1/accounts/${customerAccountId}/editSelectedOperatorRates/${companyId}`
    return httpService.put(url, body)
  },
}
