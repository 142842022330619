import { CompanyTierLabel, TierLabel } from "./enum";

/**
 * Given a quality score, returns the corresponding CompanyTierLabel.
 * If the quality is null, returns CompanyTierLabel.NoTier.
 * If the quality is 0.8 or greater, returns CompanyTierLabel.Tier1.
 * If the quality is 0.6 or greater but less than 0.8, returns CompanyTierLabel.Tier2.
 * If the quality is less than 0.6, returns CompanyTierLabel.Tier3.
 * @param {number} quality - the quality score
 * @returns {string} the company tier label
 */
export const getTierLabelByQuality = (quality: number): string => {
  if (quality == null) {
    return CompanyTierLabel.NoTier
  }
  if (quality >= 0.8) {
    return CompanyTierLabel.Tier1 as string
  } else if (quality >= 0.6) {
    return CompanyTierLabel.Tier2 as string
  } else {
    return CompanyTierLabel.Tier3 as string
  }
}

/**
 * Given a company tier label and a reservation tier label, returns true if the company tier
 * is less than the reservation tier. Otherwise, returns false.
 * @param {CompanyTierLabel} companyTierLabel - the company tier label
 * @param {TierLabel} reservationTierLabel - the reservation tier label
 * @returns {boolean} true if the company tier is less than the reservation tier, false otherwise
 */
export const isCompanyTierLessThanReservationTierByLabels = (companyTierLabel: CompanyTierLabel, reservationTierLabel: TierLabel): boolean => {
  const companyTier2ReservationVIP = CompanyTierLabel.Tier2 === companyTierLabel && TierLabel.Vip === reservationTierLabel
  const companyTier3ReservationVIPOrPlus =
  CompanyTierLabel.Tier3 === companyTierLabel && (TierLabel.Vip === reservationTierLabel || TierLabel.Plus === reservationTierLabel)
  const companyNoTierAndReservationNotNull = CompanyTierLabel.NoTier === companyTierLabel && reservationTierLabel !== null

  return companyTier2ReservationVIP || companyTier3ReservationVIPOrPlus || companyNoTierAndReservationNotNull
}
