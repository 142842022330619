import { render, staticRenderFns } from "./AffiliateTableActions.vue?vue&type=template&id=0d7cd47f&scoped=true"
import script from "./AffiliateTableActions.vue?vue&type=script&lang=js"
export * from "./AffiliateTableActions.vue?vue&type=script&lang=js"
import style0 from "./AffiliateTableActions.vue?vue&type=style&index=0&id=0d7cd47f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d7cd47f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VLayout,VList,VListTile,VListTileTitle,VMenu,VTooltip})
