<template>
  <div class="margin-l-7 margin-r-3">
    <v-layout class="margin-t-3 margin-b-5">
      <v-layout grow>
        <v-chip
          v-if="partnerTypeLabel"
          style="margin-left: -2px"
          label
          disabled
          :class="partnerTypeLabelClass"
        >
          {{ partnerTypeLabel }}
        </v-chip>
        <v-chip
          v-if="charterUPOperatingStatusLabel"
          label
          disabled
          :class="charterUPOperatingStatusLabelClass"
        >
          {{ charterUPOperatingStatusLabel }}
        </v-chip>
      </v-layout>
      <v-flex
        shrink
        style="display: flex; align-items: center; margin-top: -5px"
      >
        <CRIcon
          v-if="hasPermissionToEdit"
          id="edit-summary-icon"
          class="edit-icon"
          color="primary"
          style="cursor: pointer"
          small
          @click.native="openEditCompanySidebar"
        >
          edit
        </CRIcon>
      </v-flex>
    </v-layout>
    <v-layout row class="margin-t-3">
      <div>
        <b>Address</b>
        <div>
          {{ formattedAddress(company.address) }}
        </div>
      </div>
    </v-layout>
    <v-layout row class="margin-t-3">
      <v-flex xs6>
        <div>
          <b>Primary Email Address</b>
          <div>{{ company.email }}</div>
        </div>
      </v-flex>
      <v-flex xs6>
        <div>
          <b>Website URL</b>
          <div>{{ company.websiteUrl }}</div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout row class="margin-t-3">
      <v-flex xs6>
        <v-layout>
          <v-flex xs6>
            <b>Company Phone</b>
            <div>{{ formattedPhone(company.phone) }}</div>
          </v-flex>
          <v-flex xs6>
            <b>Dispatch Phone</b>
            <div>
              {{ formattedPhone(company.partnerPhone) }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout>
          <v-flex xs6>
            <b>Mobile Number</b>
            <div>
              {{ formattedPhone(company.smsPhone) }}
            </div>
          </v-flex>
          <v-flex xs6>
            <b>Fax</b>
            <div>{{ formattedPhone(company.fax) }}</div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout column>
      <v-divider class="margin-y-3 border-blue-mid-light"/>
      <div v-if="company.dotUpdatedAt" class="font-11 text-gray-medium-dark margin-b-3">
        {{ dotLastRetrievedLabel }}
      </div>
      <v-layout row>
        <v-flex xs6>
          <v-layout>
            <v-flex xs6>
              <b>DOT Number</b>
              <div>{{ company.dotNumber }}</div>
            </v-flex>
            <v-flex xs6>
              <b>DOT Status</b>
              <div
                :class="{
                  'text-error-new': company.dotStatusType && (company.dotStatusType.label === 'Inactive' || dotOutOfService)
                }"
              >
                {{ dotStatusTypeLabel }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 v-if="!dotOutOfService">
          <b>Operating Authority Status</b>
          <div
            :class="{
              'text-error-new': dotAuthorityStatusLabel === 'Not Authorized'
            }"
          >
            {{ dotAuthorityStatusLabel }}
          </div>
        </v-flex>
      </v-layout>
      <v-layout v-if="!dotOutOfService" row class="margin-y-3">
        <v-flex xs6>
          <v-layout>
            <v-flex xs6>
              <b>Auth. for Passenger</b>
              <div
                :class="{
                  'text-error-new': dotAuthorizedForPassengerLabel === 'No'
                }"
              >
                {{ dotAuthorizedForPassengerLabel }}
              </div>
            </v-flex>
            <v-flex xs6>
              <b>Auth. for Hire</b>
              <div
                :class="{
                  'text-error-new': dotAuthorizedForHireLabel === 'No'
                }"
              >
                {{ dotAuthorizedForHireLabel }}
              </div>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6>
          <v-layout>
            <v-flex xs6>
              <b>Carrier Operation</b>
              <div>{{ dotCarrierOperationLabel }}</div>
            </v-flex>
            <v-flex xs6>
              <b>DOT State</b>
              <div>{{ company.dotCarrierState || 'N/A' }}</div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-divider class="margin-y-4 border-blue-mid-light"/>
    <v-layout class="margin-t-3" row>
      <v-flex xs6>
        <v-layout>
          <v-flex xs6>
            <b>Insurance On File</b>
            <div>
              {{ company.insuranceOnFile ? 'Yes' : 'No' }}
            </div>
          </v-flex>
          <v-flex xs6>
            <b>Insurance Exp. Date</b>
            <div>{{ insuranceExpirationDateLabel }}</div>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout>
          <v-flex xs6>
            <div>
              <b>Insurance Active</b>
              <div>{{ insuranceActiveTypeLabel }}</div>
            </div>
          </v-flex>
          <v-flex xs6>
            <b>Int'l Operation</b>
            <div>
              {{ company.internationalOperation ? 'Yes' : 'No' }}
            </div>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row class="margin-t-3">
      <v-flex xs6>
        <div>
          <b>Max Charter PAX</b>
          <div>{{ company.maxCharterPassenger }}</div>
        </div>
      </v-flex>
      <v-flex xs6>
        <div>
          <b>Max Mini PAX</b>
          <div>{{ company.maxMiniPassenger }}</div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { SplitFeatureFlag } from '@/utils/enum'
import { phoneFormatFilter } from '@/utils/phone'
import { getOnboardingStatusTypes } from '@/services/types'
import { DateTime } from 'luxon'
import { mapActions } from 'vuex'
import companies from '@/services/companies'

const DOT_OUT_OF_SERVICE_TYPE_ID = 3

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
    hideEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      partnerTypes: [],
      onboardingStatusTypes: [],
      charterUPOperatingStatusTypes: [],
      carrierOperationTypes: [
        {
          id: 1,
          name: 'Interstate',
        },
        {
          id: 2,
          name: 'Intrastate Only',
        },
      ],
      insuranceActiveTypes: [
        {
          id: 1,
          name: 'Active',
        },
        {
          id: 2,
          name: 'Inactive',
        },
        {
          id: 3,
          name: 'N/A',
        },
      ],
    }
  },
  computed: {
    partnerTypeLabelClass() {
      switch (this.partnerTypeLabel) {
        case 'Gold Reseller Brand':
        case 'Silver Reseller Brand':
        case 'Bronze Reseller Brand':
        case 'Platinum':
        case 'Silver':
        case 'Gold':
        case 'Bronze':
          return 'background-blue-new text-white'
        case 'Banned':
          return 'background-error-new text-white'
        case 'Non-Partner':
        case 'Internal Use':
        default:
          return 'background-gray-light text-gray-dark'
      }
    },
    charterUPOperatingStatusLabelClass() {
      switch (this.charterUPOperatingStatusLabel) {
        case 'Available':
        case 'Enterprise Only':
          return 'background-gray-light text-gray-dark'
        case 'Do Not Contact':
        case 'w/ Mgmt. Approval':
        default:
          return 'background-gray-medium text-gray-dark'
      }
    },
    partnerTypeLabel() {
      return this.getPartnerTypeName(this.company.partnerTypeId)
    },
    charterUPOperatingStatusLabel() {
      const operatingStatusId = this.company.charterUPOperatingStatusId || this.company.charterUPOperatingStatusType?.id
      return this.charterUPOperatingStatusTypes.find(s => s.id === operatingStatusId)?.label
    },
    onboardingStatusTypeKey() {
      return this.onboardingStatusTypes[this.company.onboardingStatusTypeId - 1]
        ?.key
    },
    dotLastRetrievedLabel() {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : ''
      return this.company.dotUpdatedAt ? `Last Retrieved: ${this.isoToString(this.company.dotUpdatedAt, userTimeZone)}` : ''
    },
    dotOutOfService() {
      return this.company.dotStatusType?.id === DOT_OUT_OF_SERVICE_TYPE_ID
    },
    dotStatusTypeLabel() {
      if (this.company.dotStatusType === null) {
        return 'N/A'
      }
      let result = this.company.dotStatusType?.label
      if (this.dotOutOfService && this.company.dotOutOfServiceDate) {
        result += ` (${DateTime.fromISO(this.company.dotOutOfServiceDate).toLocaleString()})`
      }
      return result
    },
    dotAuthorityStatusLabel() {
      if (this.company.dotAuthorized === null) {
        return 'N/A'
      }
      if (this.company.dotAuthorized) {
        return 'Authorized'
      }
      return 'Not Authorized'
    },
    dotAuthorizedForPassengerLabel() {
      if (this.company.dotAuthorizedForPassenger === null) {
        return 'N/A'
      }
      if (this.company.dotAuthorizedForPassenger) {
        return 'Yes'
      }
      return 'No'
    },
    dotAuthorizedForHireLabel() {
      if (this.company.dotAuthorizedForHire === null) {
        return 'N/A'
      }
      if (this.company.dotAuthorizedForHire) {
        return 'Yes'
      }
      return 'No'
    },
    dotCarrierOperationLabel() {
      if (this.company.dotInterstateAllowed === null) {
        return 'N/A'
      }
      if (this.company.dotInterstateAllowed) {
        return 'Interstate'
      }
      return 'Intrastate Only'
    },
    insuranceExpirationDateLabel() {
      return this.company.insuranceExpirationDate
        ? `${DateTime.fromISO(
            this.company.insuranceExpirationDate.split('T')[0]
          ).toFormat('MM/dd/yyyy')}`
        : ''
    },
    carrierOperationTypeLabel() {
      return (
        this.carrierOperationTypes.find(
          (cot) => cot.id === this.company.carrierOperationTypeId
        )?.name || ''
      )
    },
    insuranceActiveTypeLabel() {
      return (
        this.insuranceActiveTypes.find(
          (iat) => iat.id === this.company.insuranceActiveTypeId
        )?.name || ''
      )
    },
    hasPermissionToEdit() {
      return (
        this.$store.getters['auth/hasPermission']('canEditAffiliatesDetails') &&
        !this.hideEdit
      )
    },
  },
  watch: {
    '$store.state.split.isReady': {
      async handler(value) {
        if (!value) {
          return
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await Promise.all([this.getPartnerTypes(), this.getOnboardingTypes(), this.getCharterUPOperatingStatusTypes()])
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    isoToString(date, timeZone) {
      if (timeZone) {
        const datetime = DateTime.fromISO(date, { zone: timeZone })
        return datetime.toFormat('M/dd/yyyy t ZZZZ')
      }
      const datetime = DateTime.fromISO(date)
      return datetime.toFormat('M/dd/yyyy t')
    },
    formattedPhone(phone) {
      if (!phone) {
        return 'None'
      }
      return phoneFormatFilter(phone)
    },
    formattedAddress(address) {
      if (!address) {
        return 'None'
      }
      return address.addressName
    },
    async getPartnerTypes() {
      const partnerTypeResults = await this.$store
        .dispatch('types/getPartnerTypes')
        .catch((e) => {
          console.error(e)
        })
      this.partnerTypes = partnerTypeResults.data.resultList
    },
    getPartnerTypeName(partnerTypeId) {
      if (!partnerTypeId) {
        return ''
      }
      const foundType = this.partnerTypes.find((pt) => pt.id === partnerTypeId)
      return foundType?.label || ''
    },
    async getCharterUPOperatingStatusTypes() {
      const result = await companies.getCharterUPOperatingStatuses()
      this.charterUPOperatingStatusTypes = result?.data?.charterUpOperatingStatuses
    },
    async getOnboardingTypes() {
      const params = { pageSize: -1 }
      const onboardingStatusTypeResults = await getOnboardingStatusTypes(params)
      this.onboardingStatusTypes = onboardingStatusTypeResults.data?.resultList
    },
    openEditCompanySidebar() {
      const component = () => import('./EditCompanySidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          company: this.company,
          title: 'Edit Operator',
        },
        component,
      })
    },
  },
}
</script>
