<template>
  <div class="view-container">
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-card class="dialog-container">
        <div class="label-cont">
          <label>Notes</label>
          <button
            id="affiliates-map-button-close"
            class="close"
            @click="closeDialog"
          >
            x
          </button>
        </div>
        <textarea
          id="affiliates-map-text-area-notes"
          :value="currentCompany ? currentCompany.notes : ''"
          class="notes-input"
          cols="10"
          rows="10"
          @input="(evt) => (editNotes = evt.target.value)"
        />
        <div class="button-cont">
          <div style="margin-right: -1.5%">
            <v-btn
              id="affiliates-map-button-cancel"
              class="btn-secondaryaction"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              id="affiliates-map-button-save"
              class="btn-primaryaction"
              @click="saveDialog"
            >
              Save
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <div class="menu-container menu-container--right">
      <div class="affiliates-map--list-btn">
        <CRButton color="primary" @click="$emit('toggle-mode')">
          View List
        </CRButton>
      </div>
    </div>

    <div class="menu-container menu-container--left">
      <div
        id="affiliates-map-search-box-address"
        class="searchbox"
        @click="clusterConfig.isShown = false"
      >
        <CRInput
          id="affiliates-map-input-address"
          type="address"
          :clearable="true"
          :placeholder="'Search for an address'"
          :use-formatted-detail="true"
          identifier="event_location"
          :prepend-inner-icon="'home'"
          :manual-control="true"
          @place-selected="shiftCenter"
        />
      </div>

      <div v-if="currentCompany && openCompanyBox" class="companybox">
        <div class="label-cont">
          <button
            id="affiliates-map-company-button-close"
            style="float: right"
            @click="() => (openCompanyBox = false)"
          >
            x
          </button>
        </div>
        <div v-if="loadingCompanyBox">
          <v-layout
            column
            justify-space-around
            style="background-color: white; height: 300px"
          >
            <v-progress-circular
              style="margin: 0 auto"
              :size="70"
              indeterminate
              color="primary"
            />
          </v-layout>
        </div>
        <div v-else>
          <div class="company-name">
            {{ currentCompany ? currentCompany.name : '' }}
            <span>
              ({{
                currentCompany.companyType
                  ? capitalize(currentCompany.companyType)
                  : 'N/A'
              }})
            </span>
          </div>
          <div v-if="currentCompany.address" class="company-address">
            <p>{{ currentCompany.address.street1 }}</p>
            <p>
              {{
                `${currentCompany.address.city} ${currentCompany.address.state} ${currentCompany.address.postalCode}`
              }}
            </p>
          </div>
          <div class="vote">
            <span
              id="affiliates-map-button-toggle-blue"
              @click="(evt) => toggleColor('blue')"
            >
              <CRIcon
                :class="`icon-thumbs-up ico ${blue}`"
                :width="30"
                :height="30"
              >
                thumbs_up
              </CRIcon>
            </span>
            <span
              id="affiliates-map-button-toggle-red"
              @click="(evt) => toggleColor('red')"
            >
              <CRIcon
                :class="`icon-thumbs-down ico ${red}`"
                :width="30"
                :height="30"
              >
                thumbs_down
              </CRIcon>
            </span>
          </div>
          <div class="textarea">
            <label>Notes</label>
            <textarea
              id="affiliates-map-text-area-notes"
              :disabled="disabled"
              :value="currentCompany.notes"
            />
            <div
              id="affiliates-map-button-edit"
              class="edit-text"
              @click.stop="dialog = true"
            >
              (Edit)
            </div>
          </div>
          <v-divider />
          <div class="email">
            <div class="label">Email</div>
            {{ currentCompany.email }}
          </div>
          <div class="phone">
            <div class="label">Phone</div>
            {{ currentCompany.phone }}
          </div>
          <v-divider />
          <div class="triple-icons">
            <div class="ico-container">
              <img src="@/assets/images/clipboard_map.svg" class="ico" />
              <div class="ico-text">
                {{ currentCompany.referralCount || 0 }}
              </div>
            </div>
            <div class="ico-container">
              <img
                src="@/assets/images/driver_affiliates_map.svg"
                class="ico"
              />
              <div class="ico-text">
                {{ currentCompany.driverCount || 0 }}
              </div>
            </div>
            <div class="ico-container">
              <img src="@/assets/images/vehicles_map.svg" class="ico" />
              <div class="vehicle-count">
                <div v-if="currentCompany.vehCount.length > 0">
                  <div
                    v-for="(veh, index) in currentCompany.vehCount"
                    :key="index"
                    class="ico-text"
                  >
                    {{ veh.count }} {{ veh.type }}
                  </div>
                </div>
                <div v-else class="ico-text">0</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <gmap-map
      id="map"
      ref="gMap"
      :center="mapCenter"
      :options="{
        streetViewControl: false,
        fullscreenControl: true,
        mapTypeControl: false,
        styles: mapStyles,
      }"
      :zoom="defaultZoom"
      map-type-id="roadmap"
      style="width: 100%; height: 100%; outline: none !important"
      disabled="true"
      @tilesloaded="loaded"
    />
    <div ref="tooltip" class="g-tool">
      {{ clickedCompany.name }}
    </div>
  </div>
</template>

<script>
import { capitalize } from '../utils/string'
import { gmapApi } from 'vue2-google-maps'

import { mapStyles } from '@/components/mapStyles'
import affiliates from '@/services/affiliates'
import { authComputed } from '@/state/helpers'
import * as logger from '@/utils/logger'

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    id: {
      type: Number,
      default: () => null,
    },
    parentFilters: {
      type: Object,
      default: null,
    },
    mapFilters: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      infoWindowPos: null,
      infoWinOpen: false,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      addressName: '',
      appendState: false,
      selectedCompanyId: null,
      disabled: true,
      mapCenter: { lat: 0, lng: 0 },
      affil: [],
      address: null,
      defaultZoom: 10,
      editNotes: '',
      init: false,
      prevCompanyId: null,
      currentCompany: {},
      openCompanyBox: false,
      loadingCompanyBox: false,
      clickedCompany: {},
      dialog: false,
      blue: '',
      red: '',
      mapStyles: [
        ...mapStyles,
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
      ],
      map: null,
    }
  },
  computed: {
    ...authComputed,
    google: gmapApi,
  },
  methods: {
    capitalize,
    setCurrentCompany(data) {
      this.currentCompany = data?.data?.resultList?.[0]
      this.red = ''
      this.blue = ''

      if (this.currentCompany.opinion === -1) {
        this.red = 'active-red'
      }

      if (this.currentCompany.opinion === 1) {
        this.blue = 'active-blue'
      }

      this.currentCompany.vehCount = this.currentCompany.vehicles.reduce(
        (acc, vehicle) => {
          if (acc[vehicle.vehicleTypeName]) {
            acc[vehicle.vehicleTypeName] += 1
            return acc
          }

          acc[vehicle.vehicleTypeName] = 1
          return acc
        },
        {}
      )

      const tempCount = Object.keys(this.currentCompany.vehCount)

      this.currentCompany.vehCount = tempCount.map((vehType) => {
        return { count: this.currentCompany.vehCount[vehType], type: vehType }
      })
      this.openCompanyBox = true
      this.loadingCompanyBox = false
    },
    async loaded() {
      if (this.init || this.$refs.gMap == null) {
        return
      }
      this.init = true

      this.mapCenter = this.currentUser
        ? this.currentUser.company.address
        : null
      this.currentCompany = this.currentUser ? this.currentUser.company : null
      let data
      if (
        this.value === null ||
        this.mapFilters !== this.parentFilters?.asQueryParams()
      ) {
        data = await affiliates.getAffiliatesMap({
          pageSize: -1,
          filters: this.parentFilters?.asQueryParams(),
        })
        this.$emit('input', data.data)
        this.$emit('mapFilters', this.parentFilters?.asQueryParams())
        data = data?.data?.resultList
      } else {
        data = this.value.resultList
      }

      await this.$refs.gMap.$mapPromise.then((map) => {
        this.map = map
        this.map.disabled = 'false'
      })

      let infowindow = new this.google.maps.InfoWindow()
      infowindow.setOptions({ disableAutoPan: true })

      this.affil = data.map((dat) => {
        this.affil = this.affil.filter((affil) => {
          return affil.address && affil.address.lat && affil.address.lng
        })

        return dat
      })

      for (var i = 0; i < this.affil.length; i++) {
        let newMarker = new this.google.maps.Marker({
          position: new this.google.maps.LatLng(
            this.affil[i]?.address?.lat,
            this.affil[i]?.address?.lng
          ),
          map: this.map,
        })

        const companyInfo = this.affil[i]

        this.google.maps.event.addListener(newMarker, 'click', async () => {
          this.selectedCompanyId = companyInfo.companyId
          this.openCompanyBox = true
          this.loadingCompanyBox = true
          const data = await affiliates.getAffiliatesMapVehicles(
            companyInfo.companyId
          )
          if (
            data?.data?.resultList?.[0]?.companyId === this.selectedCompanyId
          ) {
            this.setCurrentCompany(data)
          }
        })

        this.google.maps.event.addListener(
          newMarker,
          'mouseover',
          (function (newMarker) {
            return function () {
              infowindow.setContent(companyInfo.name)
              infowindow.open(this.map, newMarker)
            }
          })(newMarker)
        )

        this.google.maps.event.addListener(
          newMarker,
          'mouseout',
          (function () {
            return function () {
              infowindow.close()
            }
          })(newMarker)
        )
      }
    },
    async shiftCenter(placeObject) {
      const { lat, lng, addressName } = placeObject.place
      this.addressName = addressName
      this.defaultZoom = 15
      this.mapCenter = { lat, lng }

      setTimeout(() => {
        const map = this.$refs.gMap // eslint-disable-line
        this.defaultZoom += 1
        this.defaultZoom -= 1
      }, 200)
    },
    async saveDialog() {
      let attempt
      try {
        if (this.currentCompany.affiliateId) {
          attempt = await affiliates.saveNotes({
            id: this.currentCompany.affiliateId,
            payload: {
              notes: this.editNotes,
              affiliateId: this.currentCompany.affiliateId,
              active: true,
              companyId: this.currentCompany.companyId,
            },
          })
        } else {
          attempt = await affiliates.createAffiliate({
            payload: {
              notes: this.editNotes,
              reviewingCompanyId: this.currentUser.companyId,
              opinion: this.currentCompany.opinion,
              companyId: this.currentCompany.companyId,
            },
          })

          this.currentCompany.affiliateId = attempt.data
        }
      } catch (e) {
        logger.log(e)
      }

      this.currentCompany.notes = this.editNotes
      this.dialog = false
    },
    closeDialog() {
      this.dialog = false
    },
    async toggleColor(color) {
      let res

      if (color === 'red') {
        this.currentCompany.opinion = -1
      }

      if (color === 'blue') {
        this.currentCompany.opinion = 1
      }
      this.red = ''
      this.blue = ''
      this[color] = `active-${color}`

      if (this.currentCompany.affiliateId) {
        res = await affiliates.saveAffiliateNotes({
          id: this.currentCompany.affiliateId,
          payload: {
            opinion: this.currentCompany.opinion,
            affiliateId: this.currentCompany.affiliateId,
            active: true,
            companyId: this.currentCompany.companyId,
          },
        })
      } else {
        res = await affiliates.createAffiliate({
          payload: {
            notes: this.editNotes,
            reviewingCompanyId: this.currentUser.companyId,
            opinion: this.currentCompany.opinion,
            companyId: this.currentCompany.companyId,
          },
        })
        this.currentCompany.affiliateId = res.data
      }

      if (this.prevCompanyId !== this.currentCompany.companyId) {
        this.affil.splice(this.currentIdx, 1)
        this.affil.push(this.currentCompany)
        this.currentIdx = this.affil.length - 1
        this.prevCompanyId = this.currentCompany.companyId
        this.appendState = false
        return
      }

      if (this.appendState) {
        this.affil.splice(this.currentIdx, 1)
        this.affil.push(this.currentCompany)
        this.currentIdx = this.affil.length - 1
        this.appendState = false
        return
      }

      this.affil.splice(this.currentIdx, 1)
      this.affil.splice(0, 0, this.currentCompany)
      this.currentIdx = 0
      this.appendState = true
    },
  },
}
</script>

<style lang="scss" scoped>
.button-cfg {
  display: flex;
  justify-content: flex-end;
}

.neutral {
  color: $gray-base;
}

.red {
  color: $red;
}

.blue {
  color: $primary;
}

.pin-size {
  width: 40px;
  height: 40px;
}

.pin-text {
  font-size: 14px;
  color: $black;
}

.ico-container {
  display: flex;

  i {
    margin-right: 15px;
    font-size: 30px;
  }
}

.ico-text {
  padding-top: 4px;
  font-size: 12px;
}

.ico {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.dialog-container {
  display: flex;
  flex-direction: column;
  height: 600px;
  padding: 4% 8% 4% 8%;
  overflow-y: hidden;
  border-radius: 5px;

  .label-cont {
    display: flex;
    justify-content: space-between;
  }

  .notes-input {
    height: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $blue-light;
  }

  .button-cont {
    display: flex;
    justify-content: flex-end;
  }

  label {
    margin: 0;
    font-size: 20px;
    line-height: 1.42857;
  }

  .close {
    float: right;
    padding: 0;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: $black;
    text-shadow: 0 1px 0 $white;
    cursor: pointer;
    background: transparent;
    border: 0;
    opacity: 0.2;
  }

  .close:hover {
    opacity: 0.4;
  }
}

.affiliate {
  &-pin {
    $trans-dur: 0.4s;
    $inner-opacity: 0.12;
    $outer-opacity: 0.075;

    position: relative;
    width: 1px;
    height: 1px;
    font-size: 40px;
    // text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    &__icon {
      position: absolute;
      top: -0.7em;
      left: -0.3em;
      z-index: 10;
      font-size: inherit;
      color: inherit;

      &:focus {
        outline: none;
      }
    }
  }
}

.view-container {
  width: 100%;
  height: 100%;
}

.affiliates-map--list-btn {
  background: #fff;
  margin: 7px 10px;
  height: 100%;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;

  .cr-btn {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}

.menu-container {
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  &--left {
    width: 315px;
    height: 46%;
    margin-top: 4.5%;
    margin-left: 44px;
  }

  &--right {
    width: 155px;
    height: 54px;
    margin-top: 40px;
    margin-right: 51px;
    right: 0;
  }
}

.companybox > * {
  margin-bottom: 3%;
}

.company-address {
  color: $gray-mid-light;

  p {
    margin: 0;
  }
}

.companybox {
  width: 335px;
  padding: 10px 15px 8px 15px;
  background-color: $white;
  border-radius: 2%;
}

.company-name {
  margin-bottom: 0;
  font-size: x-large;
  font-weight: bold;
}

.vote {
  display: flex;
  justify-content: space-between;
  width: 23%;

  i {
    color: $gray-mid-light;
  }

  .ico {
    margin-right: 10px;
    font-size: 30px;
    color: $gray-mid-light;

    &.active {
      &-red {
        color: $error;
      }

      &-blue {
        color: $blue;
      }
    }
  }
}

.edit-text {
  color: $primary;
  cursor: pointer;
}

textarea {
  resize: none;
}

.textarea {
  display: flex;
  flex-direction: column;

  textarea::-webkit-resizer {
    display: none;
  }
}

.email,
.phone {
  color: $primary;

  .label {
    color: $black;
  }
}

.triple-icons {
  display: flex;
  justify-content: space-around;
}

.triple-icons > *:nth-child(-n + 2) {
  margin-right: 10px;
}

.searchbox {
  display: flex;
  width: 335px;

  ::v-deep .cr-input {
    width: 335px;
  }
}

.tabcontainer {
  margin-right: 5%;

  .switch {
    color: $white;
    background-color: $green;
  }

  button {
    padding: 0;
    margin-top: 1px;
    border-radius: 5px;
  }
}

.display-ico {
  margin-right: 15px;
  font-size: 30px;
}

.g-tool {
  display: flex;
}

.button-cfg ::v-deep button.v-btn.theme--light {
  position: relative;
  left: 25px;
  height: 20px;

  i {
    font-size: 16px;
  }
}

::v-deep .v-input__slot {
  background-color: $white !important;
}

::v-deep .v-input__icon--append {
  display: none;
}

.button-cfg ::v-deep .v-btn:hover::before {
  background-color: transparent;
}

.banner {
  background-color: $primary;
  color: $white;
}
</style>
