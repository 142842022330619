<template>
  <CRSelect
    :id="`${id}-dot-status-type-filter-select-dot-status-type`"
    v-model="dotStatusTypeSelections"
    :items="dotStatusTypes"
    chips
    flat
    hide-details
    item-text="label"
    item-value="id"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dotStatusTypes: [
        {
          id: 1,
          label: 'Active',
        },
        {
          id: 2,
          label: 'Inactive',
        },
        {
          id: 3,
          label: 'Out of Service',
        },
      ],
      dotStatusTypeSelections: [],
      filter: {
        column: {
          _t_id: 'fe43a778-300e-11ef-9454-0242ac120002',
          prop: 'dotStatusType/id',
          filterType: 'eq',
        },
        activeFilter: undefined,
        hideOnFilterBar: true,
      },
    }
  },
  async mounted() {
    const activeFilter = this.findFilter(this.filter)
    if (activeFilter) {
      this.activeFilter = activeFilter
      this.$nextTick(() => {
        const rebuiltSelections = activeFilter.value
        if (!!rebuiltSelections) {
          this.dotStatusTypeSelections = rebuiltSelections
            .split(' ')
            .reduce((acc, selection) => {
              const num = parseInt(selection)
              if (!isNaN(num)) {
                acc.push(num)
              }
              return acc
            })
          this.addFilter()
        }
      })
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.filters.remove(this.filter)

      if (this.dotStatusTypeSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addDOTStatusTypeFilter()
      }

      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addDOTStatusTypeFilter() {
      const newFilter = deepClone(this.filter)
      newFilter.value = this.dotStatusTypeSelections.join(' ')
      newFilter.stepParent = this.column?._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>
