<template>
  <div>
    <div v-if="column.displayType === 'clickable-email'">
      <a
        id="`action-column-href-mail-to-email`"
        :href="`mailto:${rowProps.item.email}`"
        target="_blank"
      >
        {{ rowProps.item.email }}
      </a>
    </div>
    <div v-if="column.displayType === 'clickable-company'">
      <router-link
        id="`action-column-link-clickable-company`"
        :to="{ path: `/companies/view/${rowProps.item.companyId}` }"
        class="no-underline"
        target="_blank"
        @click.stop="() => {}"
      >
        {{ rowProps.item.companyName || rowProps.item.companyId }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'clickable-reservation-broker'">
      <router-link
        id="`action-column-clickable-reservation-broker`"
        target="_blank"
        :to="{ path: buildReservationPath(row) }"
        class="no-underline"
      >
        {{
          row.reservation ? row.reservation.managedId : isTicketQuoteManagedId
        }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'clickable-reservation-broker-parentId'">
      <router-link
        id="`action-column-clickable-reservation-broker-parentId`"
        target="_blank"
        :to="{
          name: 'reservation-detail',
          params: { id: row.reservation.parentReservationId },
        }"
        class="no-underline"
      >
        {{ row.reservation.parentReservationId }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'clickable-cancellations-market'">
      <div
        id="`action-column-link-clickable-cancellations-market`"
        class="clickable-text no-underline"
        @click.stop="emitMarketClick(rowProps.item.id)"
      >
        {{ rowProps.item.name }}
      </div>
    </div>
    <div v-if="column.displayType === 'clickable-cancellations-company'">
      <div
        id="`action-column-link-clickable-cancellations-company`"
        class="clickable-text no-underline"
        @click.stop="emitCompanyClick(rowProps.item.id)"
      >
        {{ rowProps.item.name }}
      </div>
    </div>
    <div v-if="column.displayType === 'clickable-cancellation-type'">
      <div
        id="`action-column-link-clickable-cancellation-type`"
        class="clickable-text no-underline"
        @click.stop="
          emitCancellationTypeClick(rowProps.item.cancellation_type_id || -1)
        "
      >
        {{ rowProps.item.cancellation_type }}
      </div>
    </div>
    <div v-if="column.displayType === 'reservation'">
      <router-link
        v-if="row.reservationId !== 0"
        :id="`action-column-reservation-link`"
        target="_blank"
        :to="{ name: `reservation-detail`, params: { id: row.reservationId } }"
      >
        {{ row.reservationId }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'res-multi'">
      <div v-for="(item, index) in row.reservationIds" :key="index">
        <router-link
          :id="`action-column-reservation-link-${index}`"
          :to="{ path: `/reservations/${item}` }"
        >
          {{ item }}
        </router-link>
      </div>
    </div>
    <div v-if="column.displayType === 'action-type-list-previous'">
      <div v-for="(item, index) in row.previousValue" :key="index">
        <span>
          <b>{{ item.field }}</b>
          : {{ item.value }}
        </span>
      </div>
    </div>
    <div v-if="column.displayType === 'action-type-list-new'">
      <div v-for="(item, index) in row.newValue" :key="index">
        <b>{{ item.field }}</b>
        : {{ item.value }}
      </div>
    </div>
    <div v-if="column.displayType === 'clickable-reservation-parent'">
      <router-link
        id="`action-column-link-clickable-reservation-parent-new-tab`"
        target="_blank"
        :to="{ path: `/reservations/${row.reservation.parentReservationId}` }"
        class="no-underline"
      >
        {{ row.reservation.parentReservationId }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'clickable-ticket'">
      <router-link
        id="`action-column-link-clickable-ticket-new-tab`"
        target="_blank"
        :to="{ path: `/tickets/${rowProps.item.ticketId}` }"
        class="no-underline"
      >
        {{ rowProps.item.ticketId }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'clickable-reservation-provider'">
      <router-link
        id="`action-column-clickable-reservation-provider-new-tab`"
        target="_blank"
        :to="{ path: reservationViewPath(row) }"
        class="no-underline"
      >
        {{
          row.reservation ? row.reservation.managedId : isTicketQuoteManagedId
        }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'clickable-customer'">
      <router-link
        id="`action-column-clickable-customer-new-tab`"
        target="_blank"
        :to="{ path: `/customers/${rowProps.item.customerId}/details` }"
        class="no-underline"
      >
        {{ rowProps.item.firstName }} {{ rowProps.item.lastName }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'operator-referrals'">
      <router-link
        id="`action-column-operator-referrals-new-tab`"
        target="_blank"
        :to="{
          name: 'referrals',
        }"
        class="no-underline"
        @click.native="setReferralFilterStore(rowProps, column)"
      >
        {{ rowProps.item.name }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'broker-referrals'">
      <router-link
        id="`action-column-broker-referrals-new-tab`"
        target="_blank"
        :to="{
          name: 'referrals',
        }"
        class="no-underline"
        @click.native="setReferralFilterStore(rowProps, column)"
      >
        {{ rowProps.item.first_name }} {{ rowProps.item.last_name }}
      </router-link>
    </div>
    <div v-if="column.displayType === 'clickable-operator'">
      <div :key="row.companyId">
        <router-link
          :id="`${id}-actionable-column-link-operator-new-tab`"
          target="_blank"
          :to="{
            name: 'affiliates-detail',
            params: {
              id: row.companyId,
            },
          }"
          class="no-underline"
          @click="(event) => event.stopPropagation()"
        >
          <span>{{ row.companyId }}</span>
        </router-link>
      </div>
    </div>
    <div
      v-if="column.displayType === 'clickable-customer-id'"
      :key="`customer-id-${row.customerId}`"
    >
      <div>
        <router-link
          :id="`${id}-actionable-column-link-operator-new-tab`"
          target="_blank"
          :to="{
            name: 'customers.details',
            params: {
              id: row.customerId,
            },
          }"
          class="no-underline"
          @click="(event) => event.stopPropagation()"
        >
          <span>{{ row.customerId }}</span>
        </router-link>
      </div>
    </div>
    <div v-if="column.displayType === 'normal'">
      <span>{{ rowProps.item.companyName }}</span>
    </div>
    <div
      v-if="column.displayType === 'severity'"
      style="display: flex; align-items: center"
    >
      <CRIcon
        v-if="rowProps.item.severity === 'Urgent'"
        style="margin-right: 3px"
        :width="16"
        :height="16"
        color="lightGray"
        class="icon-lightGray"
      >
        extreme
      </CRIcon>
      <CRIcon
        v-if="rowProps.item.severity === 'High'"
        style="margin-right: 3px"
        :width="16"
        :height="16"
        color="lightGray"
        class="icon-lightGray"
      >
        high
      </CRIcon>
      <CRIcon
        v-if="rowProps.item.severity === 'Medium'"
        style="margin-right: 3px"
        :width="16"
        :height="16"
        color="lightGray"
        class="icon-lightGray"
      >
        medium
      </CRIcon>
      <CRIcon
        v-if="rowProps.item.severity === 'Low'"
        style="margin-right: 3px"
        :width="16"
        :height="16"
        color="lightGray"
        class="icon-lightGray"
      >
        low
      </CRIcon>
      &nbsp;{{ rowProps.item.severity }}
    </div>
    <div v-if="column.displayType === 'list'">
      <div :key="row.customerId">
        <div v-for="(item, key) in actionList" :key="key">
          {{ op(item, column.listProp) }}
        </div>
      </div>
    </div>
    <div v-if="column.displayType === 'coordinates'">
      <a
        v-if="row.lat !== null && row.lng !== null"
        :id="`action-column-coordinates-link`"
        :href="`https://www.google.com/maps/search/?api=1&query=${row.lat},${row.lng}`"
        target="_blank"
      >
        {{ row.lat }}, {{ row.lng }}
      </a>
    </div>
    <div v-if="column.displayType === 'license-expiration-date'">
      {{ licenseExpirationDate }}
    </div>
  </div>
</template>

<script>
import op from 'simple-object-path'
import { columnDisplay } from '@/utils/columnDisplay'
import {
  buildReservationPath,
  reservationViewPath,
} from '@/utils/reservationPathBuilder'
import { setReferralsFilterValues } from '@/utils/referralsFilters'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      op,
      columnDisplay,
      resStatusMap: {
        finished: 'completed',
        started: 'in-progress',
        cancelled: 'upcoming',
        hold: 'upcoming',
      },
      actionList: [],
      action: '',
      buildReservationPath,
      reservationViewPath,
    }
  },
  computed: {
    licenseExpirationDate() {
      const month =
        this.row.licenseExpirationMonth?.toString()?.length === 1
          ? `0${this.row.licenseExpirationMonth}`
          : this.row.licenseExpirationMonth
      const year = this.row.licenseExpirationYear
        ?.toString()
        ?.substring(this.row.licenseExpirationYear?.toString()?.length - 2)
      return month + '/' + year
    },
    isTicketQuoteManagedId() {
      // Returns null for ticket TV rows so managedId isn't duplicated on quotes/reservations columns
      if (this.row.ticketId && this.row.managedId && this.row.quoteId) {
        return null
      }
      return this.row.managedId
    },
  },
  mounted() {
    this.assignDefaults()
  },
  updated() {
    this.assignDefaults()
  },
  methods: {
    setReferralFilterStore(rowProps, column) {
      let filterValues = {
        companyId: rowProps.item.company_id,
        companyName: rowProps.item.name,
        fromDate: column.fromDate,
        toDate: column.toDate,
        dateToFilterOn: column.dateToFilterOn,
        marketplaceSelection: column.marketplaceFilter,
        referralCreationMethodSelection: column.referralCreationMethodFilter,
        cancelledReferrals: false,
        firstName: rowProps.item.first_name,
        lastName: rowProps.item.last_name,
      }
      setReferralsFilterValues(filterValues)
    },
    assignDefaults() {
      this.action = this.column.action
      if (this.column.list) {
        this.actionList = op(this.row, this.column.list) || []
      }
    },
    emitMarketClick(marketId) {
      EventBus.$emit('clicked-market', marketId)
    },
    emitCompanyClick(companyId) {
      EventBus.$emit('clicked-company', companyId)
    },
    emitCancellationTypeClick(cancellationTypeId) {
      EventBus.$emit('clicked-cancellation-type', cancellationTypeId)
    },
    async findParentReservationId(reservationId) {
      const reservationData = await this.$store.dispatch(
        'reservations/reservationById',
        {
          reservationId,
        }
      )
      return reservationData.data.parentReservationId
        ? reservationData.data.parentReservationId
        : reservationId
    },
  },
}
</script>

<style lang="scss" scoped>
.padded {
  padding: 4px;
}

.no-underline {
  text-decoration: none;
}

.icon-lightGray {
  color: $light-gray;
}

.clickable-text {
  color: $primary;
  cursor: pointer;
}
</style>
