<template>
  <v-layout :key="row.companyId" align-center class="w-max">
    <span>
      {{ row.partnerTypeLabel }}
    </span>
    <img v-if="row.preferred" height="17" class="padding-l-1" src="@/assets/images/CharterUP.svg" />
  </v-layout>
</template>

<script>

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
  }
}
</script>
