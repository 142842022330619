<template>
  <v-layout column sheet>
    <div v-show="tableProps.list.length > 0">
      <h1 class="page-header">CharterUP Rates</h1>
      <DataTable v-bind="tableProps" />
    </div>
    <div v-show="tableProps.list.length === 0">
      <h1 class="page-header">This company has not set up CharterUP Rates.</h1>
    </div>
  </v-layout>
</template>

<script>
import DataTable from '@/components/DataTable.vue'
import marketRates from '@/services/marketRates'
import { calculatedValues } from '@/utils/predefined'
import { authComputed } from '@/state/helpers'
import { currencyFilter } from '@/utils/currency'
import { filter } from '@/utils/filter'

export default {
  components: {
    DataTable,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    props: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      debounce: undefined,
      filters: () => [],
      sorts: () => [],
      user: {},
      itemsPerPage: -1,
      page: 1,
      isAdmin: false,
      resultToTableMapper: {
        'Daily Rate': 'daily',
        'Hourly Rate': 'hourly',
        'Mileage Rate': 'liveMile',
        'Dead Mile Rate': 'deadMile',
        'Transfer Rate': 'transfer',
      },
      tableProps: {
        enableExport: false,
        enableColumnConfig: false,
        total: 0,
        tableId: 'rates_table_view',
        currentPage: 1,
        perPage: -1,
        list: [],
        sort: this.sort,
        changePage: this.changePage,
        addFilter: this.addFilter,
        removeFilter: this.removeFilter,
        addNewEnabled: false,
        setSort: this.setSort,
        detailKeyId: 'marketRateId',
        isDetailed: false,
        shareFilters: this.receiveFilters,
        shareSorts: this.receiveSorts,
        loading: true,
        isAdmin: this.isAdmin,
        calculatedValues,
        columns: [
          {
            _t_id: 'f4dfd5e0',
            text: 'Vehicle',
            prop: 'vehicleName',
          },
          {
            _t_id: 'f4fff72a',
            text: 'Type',
            prop: 'vehicleType',
          },
          {
            _t_id: 'f4dfd856',
            text: 'Transfer',
            prop: 'transfer',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: 'f4dfd978',
            text: 'Dead Mile',
            prop: 'deadMile',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: 'f4dfdd06',
            text: 'Live Mile',
            prop: 'liveMile',
            computedText: (item) => currencyFilter(item),
          },
          {
            _t_id: 'f4dfde64',
            text: 'Hourly',
            prop: 'hourly',
            computedText: (item) => currencyFilter(item),
          },
          { _t_id: 'f4d3de64', text: 'Hr Min', prop: 'hourlyMinimum' },
          {
            _t_id: '1711f7ce',
            text: 'Daily',
            prop: 'daily',
            computedText: (item) => currencyFilter(item),
          },
        ],
      },
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    vehicleType: {
      deep: true,
      async handler() {
        if (!this.vehicleType) return []
        const filterObject = {
          column: {
            _t_id: 'f4fff72a',
            prop: 'vehicleType',
            filterType: 'contains',
          },
          value: this.vehicleType,
        }
        const vehicleTypeFilter = filter()
        const parentFilter = vehicleTypeFilter.createParent('and')
        vehicleTypeFilter.add(parentFilter, filterObject)
        const { data } = await this.$store.dispatch('vehicles/getVehicles', {
          page: 1,
          pageSize: -1,
          filters: vehicleTypeFilter.asQueryParams(),
        })
        this.vehicles = data.resultList.map(
          ({ vehicleId, name, vehicleName, passengerCapacity }) => {
            const text = `${vehicleName} - ${passengerCapacity} pax`
            return { vehicleId, name, vehicleName, text }
          }
        )
      },
    },
  },
  async mounted() {
    await this.getTable()
  },
  methods: {
    receiveFilters(filters) {
      this.filters = filters
    },
    receiveSorts(sorts) {
      this.sorts = sorts
    },
    addFilter() {
      this.currentPage = 1
      this.refresh()
    },
    removeFilter() {
      this.currentPage = 1
      this.refresh(true)
    },
    refresh(immediate) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      if (immediate) {
        this.getTable()
      } else {
        this.debounce = setTimeout(this.getTable, 500)
      }
    },
    async getTable() {
      const sorts = this.sorts.asQueryParams()
      const marketplaceFilterObject = {
        column: {
          _t_id: 'f4fff72a',
          prop: 'marketplace',
          filterType: 'eq',
        },
        value: true,
      }
      const marketRateFilter = filter()
      const parentFilter = marketRateFilter.createParent('and')
      marketRateFilter.add(parentFilter, marketplaceFilterObject)

      const companyId = this.row.companyId
      const params = {
        sorts,
        marketRateFilter,
        companyId,
        showAllRates: true,
        pageSize: this.itemsPerPage,
        page: this.currentPage,
        reservations: 'company',
      }
      this.tableProps.loading = true
      let results = await marketRates
        .tableView(params)
        .then((d) => d.data.resultList)
        .catch((e) => {})
      this.tableProps.list = []

      results
        .sort((a, b) => {
          if (a.vehicleId === null) return -1
        })
        .forEach((_marketRate) => {
          if (
            this.row.companyId === _marketRate.companyId &&
            _marketRate?.marketplace
          ) {
            const alreadyInputElementsWithSameVehicleIdAndType = this.tableProps.list.filter(
              (rate) =>
                rate.vehicleType === _marketRate.vehicleType &&
                rate.vehicleId === _marketRate.vehicleId
            )
            let vehicleMarketRate
            if (alreadyInputElementsWithSameVehicleIdAndType?.[0]) {
              vehicleMarketRate =
                alreadyInputElementsWithSameVehicleIdAndType?.[0]
            } else {
              vehicleMarketRate = {
                vehicleType: _marketRate.vehicleType,
                vehicleName: _marketRate.vehicleId
                  ? _marketRate.vehicleName
                  : 'Default',
                vehicleId: _marketRate.vehicleId,
                originalData: [],
              }
              this.tableProps.list.push(vehicleMarketRate)
            }
            vehicleMarketRate[
              this.resultToTableMapper[_marketRate.marketRateType]
            ] = _marketRate.highRate
            if (_marketRate.marketRateType === 'Hourly Rate') {
              vehicleMarketRate.hourlyMinimum = _marketRate.hourlyMinimum
            }
            vehicleMarketRate.originalData.push(_marketRate)
          }
        })

      await this.setCalculateRates()
      this.tableProps.perPage = this.itemsPerPage
      this.tableProps.currentPage = this.currentPage
      this.tableProps.total = this.tableProps.list.count
      this.tableProps.loading = false
    },
    async setCalculateRates() {
      const { data } = await this.$store.dispatch('vehicles/getVehicles', {
        page: 1,
        pageSize: -1,
      })
      this.calculateRatesVehicles = data.resultList.map(
        ({ vehicleId, vehicleName, passengerCapacity, vehicleTypeName }) => {
          const text = `${vehicleName} - ${passengerCapacity} pax`
          return {
            vehicleId,
            vehicleName: name,
            text,
            vehicleType: vehicleTypeName,
          }
        }
      )
    },
    sort(sortItem) {
      this.sorts.add(sortItem)
      this.refresh(true)
    },
    changePage(pagination) {
      this.currentPage = pagination.page
      this.itemsPerPage = pagination.rowsPerPage
      this.refresh()
    },
  },
}
</script>
