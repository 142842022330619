<template>
  <CRSelect
    :id="`${id}-multi-market-filter-select-market-id`"
    ref="radiusMarket"
    v-model="marketSelections"
    :items="markets"
    chips
    class="radius-selector"
    flat
    hide-details
    item-text="marketName"
    item-value="marketId"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import markets from '@/services/markets'
import { deepClone } from '@/utils/deepClone'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      markets: [],
      marketSelections: [],
      marketLocaitionFilter: {
        column: {
          _t_id: '1eb6452a',
          prop: 'nearestMarketId',
          type: 'number',
          filterType: 'eq',
        },
      },
      activeMarketLocationFilter: undefined,
    }
  },
  computed: {
    ...authComputed,
  },
  async mounted() {
    const marketsData = await markets.tableView({
      pageSize: -1,
    })

    this.markets = marketsData?.data?.resultList || []
    this.markets.sort((a, b) => (a.marketName > b.marketName ? 0 : -1))
    const activeMarketLocationFilter = this.findFilter(
      this.marketLocaitionFilter
    )
    if (activeMarketLocationFilter) {
      this.activeMarketLocationFilter = activeMarketLocationFilter
      this.$nextTick(() => {
        const rebuiltMarketSelections = activeMarketLocationFilter.value
        if (!!rebuiltMarketSelections) {
          this.marketSelections = rebuiltMarketSelections
            .split(' ')
            .reduce((acc, selection) => {
              const num = parseInt(selection, 10)
              if (!isNaN(num)) {
                acc.push(num)
              }
              return acc
            })
          this.addFilter()
        }
      })
    }
  },
  methods: {
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter(e) {
      if (this.findFilter(this.activeMarketLocationFilter)) {
        this.filters.remove(this.activeMarketLocationFilter)
      }
      if (this.marketSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addMarketLocationFilter()
        this.addFilter()
      }
      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addMarketLocationFilter() {
      const cloneMarketSelections = [].concat(this.marketSelections)
      const newFilter = deepClone(this.marketLocaitionFilter)
      newFilter.hideOnFilterBar = true
      newFilter.value = cloneMarketSelections.join(' ')
      this.activeMarketLocationFilter = newFilter
      newFilter.stepParent = this.column._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>

<style lang="scss" scoped>
.radius-control-container {
  padding: 4px;
}

.radius-selector {
  width: 300px !important;
  color: $primary;
  background-color: $white;
  border: 1px solid $primary;
  // appearance: none;
  border-radius: 4px;
}

.radius-selector::v-deep {
  .v-input__control {
    min-height: 42px;
  }

  .theme--light.v-chip {
    color: $white !important;
    background: $primary !important;
  }
}
</style>
