<template>
  <CRSelect
    :id="`${id}-onboarding-status-type-filter-select-onboarding-status-type`"
    v-model="onboardingStatusTypeSelections"
    :items="onboardingStatusTypes"
    chips
    flat
    hide-details
    item-text="label"
    item-value="id"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { getOnboardingStatusTypes } from '@/services/types'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      onboardingStatusTypes: [],
      onboardingStatusTypeSelections: [],
      filter: {
        column: {
          _t_id: '5422ad86',
          prop: 'onboardingStatusTypeId',
          filterType: 'eq',
        },
        activeFilter: undefined,
        hideOnFilterBar: true,
      },
    }
  },
  async mounted() {
    const params = { pageSize: -1 }
    const onboardingStatusTypeResults = await getOnboardingStatusTypes(params)
    this.onboardingStatusTypes = onboardingStatusTypeResults.data?.resultList

    const activeFilter = this.findFilter(this.filter)
    if (activeFilter) {
      this.activeFilter = activeFilter
      this.$nextTick(() => {
        const rebuiltSelections = activeFilter.value
        if (!!rebuiltSelections) {
          this.onboardingStatusTypeSelections = rebuiltSelections
            .split(' ')
            .reduce((acc, selection) => {
              const num = parseInt(selection)
              if (!isNaN(num)) {
                acc.push(num)
              }
              return acc
            })
          this.addFilter()
        }
      })
    }
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.filters.remove(this.filter)

      if (this.onboardingStatusTypeSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addOnboardingStatusTypeFilter()
      }

      this.$emit('make-dirty-request')
      this.addFilter()
    },
    addOnboardingStatusTypeFilter() {
      const newFilter = deepClone(this.filter)
      newFilter.value = this.onboardingStatusTypeSelections.join(' ')
      newFilter.stepParent = this.column?._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>
