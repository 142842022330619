<template>
  <CRSelect
    :id="`${id}-partner-type-filter-select-partner-type`"
    v-model="partnerTypeSelections"
    :items="partnerTypes"
    chips
    flat
    hide-details
    item-text="label"
    item-value="id"
    multiple
    solo
    @input="setFilter"
  />
</template>

<script>
import { deepClone } from '@/utils/deepClone'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions } from 'vuex'

export default {
  props: {
    filters: {
      type: Object,
      default: () => undefined,
    },
    control: {
      type: Object,
      default: () => undefined,
    },
    column: {
      type: Object,
      default: () => undefined,
    },
    addFilter: {
      type: Function,
      default: () => () => true,
    },
    id: {
      type: String,
      default: null,
    },
    setReferralFilter: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      partnerTypes: [],
      partnerTypeSelections: [],
      filter: {
        column: {
          _t_id: '1cez2d54',
          prop: 'partnerTypeId',
          filterType: 'eq',
        },
        activeFilter: undefined,
        hideOnFilterBar: true,
      },
    }
  },
  async mounted() {
    const partnerTypeResults = await this.$store.dispatch(
      'types/getPartnerTypes'
    )
    this.partnerTypes = partnerTypeResults.data.resultList

    const activeFilter = this.findFilter(this.filter)
    if (activeFilter) {
      this.activeFilter = activeFilter
      this.$nextTick(() => {
        const rebuiltSelections = activeFilter.value
        if (!!rebuiltSelections) {
          this.partnerTypeSelections = rebuiltSelections
            .split(' ')
            .reduce((acc, selection) => {
              const num = parseInt(selection)
              if (!isNaN(num)) {
                acc.push(num)
              }
              return acc
            })
          this.addFilter()
        }
      })
    }
  },
  methods: {
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    findFilter(filter) {
      return filter && this.filters.find(filter)
    },
    setFilter() {
      this.filters.remove(this.filter)

      if (this.partnerTypeSelections.length) {
        const grandParent = this.filters.createParent('and')
        this.grandParentFilter = grandParent
        this.addPartnerTypeFilter()
      }

      this.$emit('make-dirty-request')
      this.addFilter()
      this.setReferralFilter(this.partnerTypeSelections)
    },
    addPartnerTypeFilter() {
      const newFilter = deepClone(this.filter)
      newFilter.value = this.partnerTypeSelections.join(' ')
      newFilter.stepParent = this.column?._t_id
      this.filters.add(this.grandParentFilter, newFilter)
    },
  },
}
</script>
