<template>
  <AffiliatesMap
    v-if="affiliatesMode === 'map'"
    v-model="affiliates"
    :parent-sorts="sorts"
    :parent-filters="filters"
    :map-filters="mapFilters"
    @mapFilters="updateMapFilters"
    @toggle-mode="affiliatesMode = 'list'"
  />
  <AffiliatesList
    v-else-if="affiliatesMode === 'list'"
    :parent-sorts="sorts"
    :parent-filters="filters"
    @toggle-mode="affiliatesMode = 'map'"
    @sorts="updateSorts"
    @filters="updateFilters"
  />
</template>
<script>
import AffiliatesMap from '@/components/AffiliatesMap'
import AffiliatesList from '@/components/AffiliatesList'
export default {
  components: {
    AffiliatesMap,
    AffiliatesList,
  },
  props: {
    mode: {
      type: String,
      default: 'map',
    },
  },
  data() {
    return {
      affiliatesMode: null,
      affiliates: null,
      sorts: null,
      filters: null,
      mapFilters: '',
    }
  },
  watch: {
    mode(value) {
      this.affiliatesMode = value
    },
  },
  mounted() {
    this.affiliatesMode = this.mode
  },
  methods: {
    updateMapFilters(mapFilters) {
      this.mapFilters = mapFilters
    },
    updateFilters(filters) {
      this.filters = filters
    },
    updateSorts(sorts) {
      this.sorts = sorts
    },
  },
}
</script>
